var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"app"}},[_c('HeroLong',_vm._b({attrs:{"playerresize":"win","data-setup":JSON.stringify({
        fluid: true,
        xchildren: { loadingSpinner: false },
        html5: {
          hls: {
            overrideNative: true,
          },
        },
      })}},'HeroLong',{
      title: _vm.$t('entry.hero.title'),
      poster: '/images/bg2_screen.jpg',
      brand: _vm.$t('entry.hero.brand'),
      cta: _vm.$t('entry.hero.cta'),
    },false),[_c('source',{attrs:{"src":"/video/win.m3u8","type":"application/x-mpegURL"}})]),_vm._v(" "),_c('Navbar',{staticStyle:{"position":"absolute","top":"0","width":"100%","z-index":"1000","background":"transparent"},attrs:{"brand":true}}),_vm._v(" "),_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('nuxt',{staticClass:"main-content",attrs:{"id":"page-content"}}),_vm._v(" "),_c('MainContentRight',{staticClass:"col-12 col-md-4 ml-md-auto"})],1)]),_vm._v(" "),_c('Footer'),_vm._v(" "),_c('Notification'),_vm._v(" "),_c('Cookie')],1)}
var staticRenderFns = []

export { render, staticRenderFns }