var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"app"}},[_c('HeroLong',_vm._b({attrs:{"playerresize":"about","data-setup":JSON.stringify({
        fluid: true,
        html5: {
          hls: {
            overrideNative: true,
          },
        },
      })}},'HeroLong',{
      overlay: false,
      poster: '/images/bg2_screen.jpg',
      xlink: {
        path: _vm.$t('about.hero.link.path'),
        text: _vm.$t('about.hero.link.text'),
      },
    },false),[(_vm.$mq === 'sm' || _vm.$mq === 'md' || _vm.$mq === 'xs')?_c('source',{attrs:{"src":"/video/about_mobile.m3u8","type":"application/x-mpegURL"}}):_c('source',{attrs:{"src":"/video/index.m3u8","type":"application/x-mpegURL"}})]),_vm._v(" "),_c('Navbar',{staticStyle:{"position":"absolute","top":"0","width":"100%","z-index":"1000","background":"transparent"},attrs:{"brand":true}}),_vm._v(" "),_c('div',{staticClass:"container",staticStyle:{"min-height":"100vh"}},[_c('Pagination'),_vm._v(" "),_c('nuxt',{staticClass:"main-content",attrs:{"id":"page-content"}})],1),_vm._v(" "),_c('Footer'),_vm._v(" "),_c('Notification'),_vm._v(" "),_c('Cookie')],1)}
var staticRenderFns = []

export { render, staticRenderFns }